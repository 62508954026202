.SecondaryMarketingHeader3977ece979884a978c8910af77797711 {
  height: 18rem;
  position: relative;
  color: #030C22;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.SecondaryMarketingHeader3977ece979884a978c8910af77797711 .background-image {
  background-image: url(https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/Moving_Forest_1050_700.webp);
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -10;
}

.SecondaryMarketingHeader3977ece979884a978c8910af77797711 .header-top-wrapper {
  position: relative;
}

.SecondaryMarketingHeader3977ece979884a978c8910af77797711 .header-top-opacity {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  background-color: white;
  z-index: 0;
}

.SecondaryMarketingHeader3977ece979884a978c8910af77797711 .header-top {
  z-index: 1;
  position: relative;
}
.SecondaryPage85be3607354943aaB96aD77baa28d19b .text-content {
  padding: 0 1rem;
}
.HomePageHero60cfe84dC8034879A4b3E823ff2fefef {
  color: #030C22;
  min-height: 35rem;
  padding: 1.5rem;
  position: relative;
  text-align: left;
}

@media (min-width: 768px) {
  .HomePageHero60cfe84dC8034879A4b3E823ff2fefef {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

.HomePageHero60cfe84dC8034879A4b3E823ff2fefef .title {
  color: black;
  font-family: 'Bebas Neue';
  font-size: 300%;
  font-weight: 500;
  line-height: 4rem;
  margin-bottom: 1.2rem;
  letter-spacing: 0.15rem;
  text-align: center;
}

.HomePageHero60cfe84dC8034879A4b3E823ff2fefef .button {
  display: flex;
  justify-content: center;
}

.HomePageHero60cfe84dC8034879A4b3E823ff2fefef .button > a {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  color: white;
  font-family: 'Bebas Neue';
  font-weight: 600;
  font-size: 150%;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  display: inline-block;
  padding: 0.75rem 3rem;
  background-color: #780050;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.HomePageHero60cfe84dC8034879A4b3E823ff2fefef .button > a:hover {
  --tw-brightness: brightness(1.1);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

@media (min-width: 900px) {
  .HomePageHero60cfe84dC8034879A4b3E823ff2fefef .title {
    margin-top: 2.2rem;
    text-align: left;
  }

  .HomePageHero60cfe84dC8034879A4b3E823ff2fefef .button {
    display: block;
    justify-content: start;
  }
}
.DefaultHeaderTop1aa3b511D6bb49e1Be5bE653345da47a {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 7rem;
  padding: 1rem;
  position: relative;
}

.DefaultHeaderTop1aa3b511D6bb49e1Be5bE653345da47a .linked-logo {
  display: flex;
  align-items: stretch;
  max-height: 100%;
}

.DefaultHeaderTop1aa3b511D6bb49e1Be5bE653345da47a .linked-logo .image-content {
  max-width: 27rem;
}

.DefaultHeaderTop1aa3b511D6bb49e1Be5bE653345da47a .linked-logo .image-content img {
  max-height: 100%;
}

/* Show/hide navigation */

@media (min-width: 900px) {
  .DefaultHeaderTop1aa3b511D6bb49e1Be5bE653345da47a {
    padding: 1.75rem 4rem;
    height: 9rem;
  }

  .DefaultHeaderTop1aa3b511D6bb49e1Be5bE653345da47a .linked-logo {
    flex-grow: 1;
  }
  .DefaultHeaderTop1aa3b511D6bb49e1Be5bE653345da47a .logo {
    width: 12rem;
  }
}
.ShortHeader9174296cD30845c4Be3dEf3bffb26e4e {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  padding: 0 1.5rem;
  background-color: white;
}

.ShortHeader9174296cD30845c4Be3dEf3bffb26e4e .linked-logo {
  display: flex;
  justify-content: center;
}

.ShortHeader9174296cD30845c4Be3dEf3bffb26e4e .logo {
  width: 8rem;
}

.ShortHeader9174296cD30845c4Be3dEf3bffb26e4e .navigation {
  margin: 1rem;
  margin-top: 2.25rem;
}

.ShortHeader9174296cD30845c4Be3dEf3bffb26e4e .Navigation {
  display: none;
  flex-grow: 1;
  gap: 2rem;
  font-family: Montserrat;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  color: var(--heading-color);
}

.ShortHeader9174296cD30845c4Be3dEf3bffb26e4e .Navigation .Link:hover {
  color: var(--accent-color);
}

.ShortHeader9174296cD30845c4Be3dEf3bffb26e4e .NavigationMenuButton {
  color: black;
  opacity: 0.5;
  display: flex;
}

.ShortHeader9174296cD30845c4Be3dEf3bffb26e4e .NavigationMenuButton svg {
  width: 1.5rem;
  height: 1.5rem;
}

/* Larger screens */

@media (min-width: 900px) {
  .ShortHeader9174296cD30845c4Be3dEf3bffb26e4e .linked-logo {
    flex-grow: 1;
  }
  .ShortHeader9174296cD30845c4Be3dEf3bffb26e4e .logo {
    width: 9rem;
  }

  .ShortHeader9174296cD30845c4Be3dEf3bffb26e4e .navigation {
    flex-grow: 4;
  }

  .ShortHeader9174296cD30845c4Be3dEf3bffb26e4e .Navigation {
    display: flex;
  }

  .ShortHeader9174296cD30845c4Be3dEf3bffb26e4e .NavigationMenuButton {
    display: none;
  }
}

@media (min-width: 1200px) {
  .ShortHeader9174296cD30845c4Be3dEf3bffb26e4e {
    padding: 0 1.75rem;
    height: 5rem;
  }

  .ShortHeader9174296cD30845c4Be3dEf3bffb26e4e .logo {
    width: 10rem;
  }
}
.HeaderWrapperBadcdfaa842f46ad92d1Becc628605f1 {
  display: flex;
  flex-direction: column;
  background-color: white;
  position: relative;
  color: #030C22;
}

.HeaderWrapperBadcdfaa842f46ad92d1Becc628605f1 .background-image {
  opacity: 0.5;
}

.HeaderWrapperBadcdfaa842f46ad92d1Becc628605f1 .header-top-wrapper {
  position: relative;
}

.HeaderWrapperBadcdfaa842f46ad92d1Becc628605f1 .header-top-opacity {
  opacity: .8;
  background-color: white;
  position: absolute;
  inset: 0;
}

.HeaderWrapperBadcdfaa842f46ad92d1Becc628605f1 .background-image {
  background-image: url(https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/Moving_Forest_1050_700.webp);
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.DefaultFooterAba12e112e9940ef9c1993f4f7233f56 {
  background-color: var(--heading-color, black);
  color: white;
  min-height: 10em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding: 2rem;
  font-family: Montserrat;
}

.DefaultFooterAba12e112e9940ef9c1993f4f7233f56 > * {
  text-align: center;
}

.DefaultFooterAba12e112e9940ef9c1993f4f7233f56 .Navigation {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 3rem;
  justify-content: center;
  margin-bottom: 2rem;
}

.DefaultFooterAba12e112e9940ef9c1993f4f7233f56 .Navigation .Link:hover {
  text-decoration-line: underline;
  --tw-brightness: brightness(1.1);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.DefaultFooterAba12e112e9940ef9c1993f4f7233f56 > .logo-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 15rem;
  height: 4.25rem;
}

.DefaultFooterAba12e112e9940ef9c1993f4f7233f56 > .logo-wrapper .image-content {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.DefaultFooterAba12e112e9940ef9c1993f4f7233f56 > .logo-wrapper img {
  max-height: 100%;
}

.DefaultFooterAba12e112e9940ef9c1993f4f7233f56 .LogoWrapper {
  font-size: 90%;
}

.DefaultFooterAba12e112e9940ef9c1993f4f7233f56 .LogoWrapper {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.DefaultFooterAba12e112e9940ef9c1993f4f7233f56 .LogoWrapper:hover {
    --tw-text-opacity: 1;
    color: rgb(0 194 152 / var(--tw-text-opacity));
}

.DefaultFooterAba12e112e9940ef9c1993f4f7233f56 .Logo {
  height: 40px;
  width: 150px;
  fill: currentColor;
}

.DefaultFooterAba12e112e9940ef9c1993f4f7233f56 .GroupCopyright {
  font-size: 90%;
}

.DefaultFooterAba12e112e9940ef9c1993f4f7233f56 .GroupFlowTag .PoweredBy {
  font-size: 90%;
}
.PageQuote071cea74F9e847949f6b951fbe480b76 {
  display: flex;
  flex-wrap: wrap;
}
.Hero6ed8e8f0De0646248262A7cafe610577 {
}

.Hero6ed8e8f0De0646248262A7cafe610577 .external-content p {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.Hero6ed8e8f0De0646248262A7cafe610577 h1 {
  font-family: Bebas Neue;
  font-size: 3rem;
  line-height: 3.75rem;
  letter-spacing: .15rem;
  font-weight: 500;
  color: var(--heading-color, var(--accent-color, black));
}

@media screen and (min-width: 768px) {
  .Hero6ed8e8f0De0646248262A7cafe610577 h1 {
    font-size: 3.5rem;
    line-height: 4rem;
  }
}


.Hero6ed8e8f0De0646248262A7cafe610577 h2 {
  margin-top: 1rem;
  font-size: 1.25rem;
  font-weight: 500;
}

@media screen and (min-width: 768px) {
  .Hero6ed8e8f0De0646248262A7cafe610577 h2 {
    font-size: 1.5rem;
  }
}

.Hero6ed8e8f0De0646248262A7cafe610577 .buttons {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}

@media screen and (min-width: 768px) {
  .Hero6ed8e8f0De0646248262A7cafe610577 .buttons {
    display: block;
  }
}
.SecondaryNavCeeb00119c1d466f89daF6f941146bcc {
  padding: 1rem 0;
}
.SecondaryMarketingHeroB9768ed0A60b467f96fdFad263a63d42 {
  align-items: center;
  position: relative;
  color: white;
  display: flex;
  flex-grow: 1;
  padding-left: 5rem;
}

.SecondaryMarketingHeroB9768ed0A60b467f96fdFad263a63d42::before {
  content: '';
  background-color: var(--heading-color, black);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0.9;
}

.SecondaryMarketingHeroB9768ed0A60b467f96fdFad263a63d42 .title {
  font-family: 'Bebas Neue';
  font-size: 300%;
  font-weight: 500;
  line-height: 100%;
}

@media (min-width: 640px) {
  .SecondaryMarketingHeroB9768ed0A60b467f96fdFad263a63d42 .title {
    font-size: 400%;
  }
}

.SecondaryMarketingHeroB9768ed0A60b467f96fdFad263a63d42 .button > a {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  color: white;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 110%;
  text-transform: uppercase;
  display: inline-block;
  padding: 0.5rem 2rem;
  background-color: #ed2e38;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.SecondaryMarketingHeroB9768ed0A60b467f96fdFad263a63d42 .button > a:hover {
  --tw-brightness: brightness(1.1);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.HeroWrapper90ce60c0Bdad4aafB1a6923a63568690 {
  min-height: 35rem;
  padding: 3.75rem 2rem;
}

@media screen and (min-width: 640px) {
  .HeroWrapper90ce60c0Bdad4aafB1a6923a63568690 {
    padding: 3.75rem 5rem;
  }
}
.HomePage1919f1cc04a849d4A64d52056612c12c {
  display: flex;
  flex-direction: column;
}

.HomePage1919f1cc04a849d4A64d52056612c12c .PageQuote {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.HomePage1919f1cc04a849d4A64d52056612c12c .PageQuote > * {
  flex-grow: 1;
}

.HomePage1919f1cc04a849d4A64d52056612c12c .PageQuote .Image {
  object-fit: cover;
}

.HomePage1919f1cc04a849d4A64d52056612c12c .PageQuote .TextContent {
  background-color: var(--accent-color-2, black);
  color: white;
  padding: 3rem;
}

.HomePage1919f1cc04a849d4A64d52056612c12c .PageQuote .Title {
  font-family: "Bebas Neue";
  font-size: 350%;
  line-height: 100%;
}

.HomePage1919f1cc04a849d4A64d52056612c12c .PageQuote .Text {
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 125%;
}

@media (min-width: 900px) {
  .HomePage1919f1cc04a849d4A64d52056612c12c .PageQuote > * {
    width: 50%;
    height: 26rem;
  }
}

@media (min-width: 1200px) {
  .HomePage1919f1cc04a849d4A64d52056612c12c .PageQuote .Title {
    font-size: 400%;
    line-height: 125%;
  }
}